import React from 'react'
import { BsPersonVcardFill , BsFillPersonFill} from 'react-icons/bs';
import { MdNotifications } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaComment } from 'react-icons/fa';


import './Css/Nav.css'
const NavBar = () => {
  return (
    <div className='navv'>
    <header className='navbar'>
        <div className='navbar__title navbar__item'><AiOutlineMenu size='25px' style={{color:'white',fontWeight:'bold',}}/></div>
        <div className='navbar__item'><BsPersonVcardFill/></div>
        <div className='navbar__item'><MdNotifications/></div>
        <div className='navbar__item'><FaComment/></div>
        <div className='navbar__item'><BsFillPersonFill/> <span className='nam'>Pravjot Singh</span></div>        
    </header>
    </div>
  )
}

export default NavBar