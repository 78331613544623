import React from 'react'
import MultiAttendance from './pages/MultiAttendance'
import ApproveAttendance from './pages/ApproveAttendance'
import Approve from './pages/Approve'
import Advance from './pages/Advance'
import TravelAllowance from './pages/TravelAllowance'
import ExtraDuty from './pages/ExtraDuty'
import Reimbursement from './pages/Reimbursement'
import CompensatoryOff from './pages/CompensatoryOff/CompensatoryOff'
import Home from './components/Home'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route index exact path="/" element={<Home/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="MultiAttendance" element={<MultiAttendance/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="ApproveAttendance" element={<ApproveAttendance/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="all-compoff-attendance-approve" element={<CompensatoryOff/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="all-compoff-leave-approve" element={<Approve/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="all-extra-duty-attendance-approve" element={<ExtraDuty/>}/>     
      </Routes>
      <Routes>
        <Route index exact path="approve-all-ta" element={<TravelAllowance/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="all-approve-reimbursement" element={<Reimbursement/>}/>      
      </Routes>
      <Routes>
        <Route index exact path="all-approve-advance" element={<Advance/>}/>      
      </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
