import React from 'react'
import { Link } from 'react-router-dom';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import NavBar from '../pages/Nav'
const Home = () => {
  return (
    <div>
        <NavBar/>
        <Link to="MultiAttendance">
            <button >Multi Attendance</button>
        </Link>
        <Link to="ApproveAttendance">
            {/* <button>Approve Attendance try</button> */}
        </Link>
        <Link to="all-compoff-attendance-approve">
            <button>APPROVALS-Comp Off Attendance</button>
        </Link>
        <Link to="all-compoff-leave-approve">
            <button>APPROVALS-Comp Off leave</button>
        </Link>
        <Link to="all-extra-duty-attendance-approve">
            <button>APPROVALS-Extra Duty</button>
        </Link>
        <Link to="approve-all-ta">
            <button>APPROVALS-Travel Allowance</button>
        </Link>
        <Link to="all-approve-reimbursement">
            <button>APPROVALS-Reimbursement</button>
        </Link>
        <Link to="all-approve-advance">
            <button>APPROVALS-Advances</button>
        </Link>
    </div>
  )
}

export default Home