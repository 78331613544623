import { TbPlayerTrackNextFilled ,TbPlayerTrackPrevFilled} from 'react-icons/tb';
import React from 'react'
import NavBar from './Nav';
import DataTable from 'react-data-table-component';
import { MdOutlineCircle ,MdOutlineSquare ,MdDelete } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { FaHome} from 'react-icons/fa';
import { BsSearch ,BsFillEyeFill,BsCheckLg} from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { RxCross2} from 'react-icons/rx';
import Select from 'react-select';
import {useState, useEffect} from 'react'
import './Css/Reimbursement.css'
import { AiFillPrinter ,AiOutlineReload,AiOutlineCheck} from 'react-icons/ai';
import { HiArrowUp,HiArrowDown} from 'react-icons/hi';



const Reimbursement = () => {

    const [selectedMonth, setSelectedMonth] = useState({
        value: String((new Date()).getMonth() + 1).padStart(2, '0'), 
        label: (new Date()).toLocaleString('default', { month: 'long' })
     });
    const [selectedYear, setSelectedYear] = useState({
       value: `${(new Date()).getFullYear()}`, label: `${(new Date()).getFullYear()}`,
     }); 
     const monthOptions = [...new Array(12)].map(x => 0).map((x, i) => x + i + 1).map(x => {
    const date = new Date()
    date.setMonth(x - 1)
    return {
        value: String(x).padStart(2, '0'),
        label: date.toLocaleString('default', { month: 'long' })
    }
})
const yearOptions = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

  return (
    <div className='main-ap'>
     <div className='side-bar-multi-ap'>
      <div className='itm'><h5 className='ltr-at'>PERSONAL</h5></div>
      <div className='itm'><h5 className='ltr-at'>SALON / SPA</h5></div>
      <div className='itm'><h5 className='ltr-at'>FOS</h5></div>
      <div className='itm'><h5 className='ltr-at'>ACCOUNTS</h5></div>
      <div className='itm'><h5 className='ltr-at'>AVENGERS.CO.IN</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-BRANCH</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-HO</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR EMPLOYEE</h5></div>
    </div>
      <div className='contai-ap'>
        <NavBar/>
          {/* -----grey bar----- */}
        <div className='home-a'>
    <div >
     <p ><FaHome/> <span style={{fontSize:'14px'}}>Home /  Approve Reimbursement</span> 
     </p>
    </div>
    <div className='icon-a'>
    <MdOutlineCircle/>
    <MdOutlineCircle/>
    <MdOutlineSquare />
    <TfiReload/>  
    </div>
  </div>
    {/* -----white bar----- */}
  <div className='xcel-a'>
    <div >
     <p style={{color: '#858585'}}>
     </p>
    </div>
    <div className='icon-a'>
    <BsSearch/><AiFillPrinter/>
    </div>
  </div>
  {/* ---- Table----- */}
  <div className='table-a'>
   
    <div className='table-wrapper-a'>
    <div className='table media-scroller-a'>
      
    <div className='tab-ap'>
        <div className='ex-text-in'>
    <h6 className='text-ap-'></h6>
     <select id="numRows" 
      // value={numRows} onChange={handleNumRowsChange}
       style={{border:'1.5px solid #50a3a5',padding:'2px' ,borderRadius:'5px',fontSize:'12px',color:'#50a3a5',maxHeight:'30px',minHeight:'30px'}}>
        <option value="" disabled hidden>
          Show
        </option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
    <div className='tb-of-app'>
     <table className='tab-of-ap' >
      <thead className='thead-ap' style={{background:'#25cacc',color:'white'}}>
        <tr>
        <th className='sl-re'>Sl No</th>
        <th className='compp-dt-at'>Date</th>
        <th className='emp-name-at' style={{position: 'sticky',top:0,left:0,background: '#25cacc',zIndex: 1, borderRight:'1.7px solid #c8c8c8',}}>Employee Name <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='emp-code-at'>Employee Code <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='sub-com-at'>Sub Company <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='typ-at'>Branch <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='dpt-at'>Department <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='sub-dpt-at'>Sub Department <HiArrowUp style={{color:'white'}} size='10px'/><HiArrowDown style={{color:'white'}} size='10px'/></th>
        <th className='desig-at'>Designation</th> 
        <th className='branch-re'>Reimbursement Type</th>                
        <th className='mbl-re'>Mobile</th> 
        <th className='days-at'>Amount</th>
        <th className='days-at'>Remarks</th>
        <th className='days-at'>Document</th>
        <th className='actn-by-at'>Action By</th>
        <th className='apprv-st-at'>Approve Status</th>
        <th className='apprv-at'>Payment Status</th>
        <th className='actn-by-attt'>Payment By</th>
        <th className='ctn-re'>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
         <tr>
        <td className='pd-at'>1</td>
        <td className='pd-at'>08-01-2023</td>
         <td className='pd-at' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-at'>25125</td>
        <td className='pd-at'>Sub Company</td>
        <td className='pd-at'>Birati</td>
        <td className='pd-at'>Department</td>
        <td className='pd-at'>Bright Future</td>
        <td className='pd-at'>Designation</td>
        <td className='pd-at'>Birthday</td>
        <td className='pd-at'>Mobile</td>
        <td className='pd-at-rt'>1,000,000.00</td>
        <td className='pd-at'>Remarks</td>
        <td className='pd-at'>-</td>
        <td className="pd-at-lf">Vijoyeta Biswas- Approved</td> 
        <td className='pd-at'>Approved</td>  
        <td className='pd-at'>Unpaid</td>
        <td className='pd-at'>-</td>
        <td className='pd-at--'><BsCheckLg size='12.5px'style={{color:'#e89c12'}}/><RxCross2  size='12.5px'style={{color:'red'}}/><BsFillEyeFill size='12.5px'/><MdDelete  size='12.5px' style={{color:'#c8c8g9'}}/></td>
        </tr>
        <tr>
          <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
          <td className='btm-xt-ta' style={{fontSize:'11px',width:'100px',textAlign:'right',padding:'0.4em 0.4em'}}>Total Applied <br/>Total Approved<br/>Total Rejected<br/>Total Pending<br/></td>
            <td className='btm-xt-ta' style={{fontSize:'11px',width:'100px',textAlign:'right',padding:'0.4em 0.4em'}}>3,486,947.00<br/>3,486,424.00<br/>523.00<br/>0.00<br/></td>
        </tr> 
      </tbody>
     </table>
     </div>
     <div className='con-pg-ta'>
  <div className='btm-ta'>
    <>
    </>
  </div>
  <nav aria-label="Page navigation example sfddf" >
  <ul class="pagination">
    <li class="page-item"> 
      <a class="page-link" href="#" aria-label="Previous">
        <span class="sr-only"><TbPlayerTrackPrevFilled size='10px'/></span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span class="sr-only"><TbPlayerTrackNextFilled size='10px'/></span>
      </a>
    </li>
  </ul>
</nav>
  </div>
  </div>
        </div>
        </div>
  </div>
  </div>
    </div>
  )
}

export default Reimbursement

