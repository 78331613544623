import React from 'react'
import NavBar from './Nav';
import DataTable from 'react-data-table-component';
import { MdOutlineCircle ,MdOutlineSquare } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { FaHome} from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import './Css/ApproveAttendance.css'
import Select from 'react-select';
import {useState, useEffect} from 'react'



const ApproveAttendance = () => {

    const [selectedMonth, setSelectedMonth] = useState({
        value: String((new Date()).getMonth() + 1).padStart(2, '0'), 
        label: (new Date()).toLocaleString('default', { month: 'long' })
     });
    const [selectedYear, setSelectedYear] = useState({
       value: `${(new Date()).getFullYear()}`, label: `${(new Date()).getFullYear()}`,
     }); 
     const monthOptions = [...new Array(12)].map(x => 0).map((x, i) => x + i + 1).map(x => {
    const date = new Date()
    date.setMonth(x - 1)
    return {
        value: String(x).padStart(2, '0'),
        label: date.toLocaleString('default', { month: 'long' })
    }
})
const yearOptions = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];


const columns = [

    {
        name: 'Employee Name',
        selector: row => row.Employee,
        minWidth: '110px',
        maxWidth: '110px',
        textAlign:'center',
        background:'#fff',
        
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          borderLeft: ' 1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Sub Company',
        selector: row => row.SubCompany ,
        minWidth: '100px',
        maxWidth: '100px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          
          background:'#fff',
      },
    },
    {
        name: 'Branch Name',
        selector: row => row.BranchName ,
        minWidth: '100px',
        maxWidth: '100px',
        textAlign:'center',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Department',
        selector: row => row.Department ,
        minWidth: '100px',
        maxWidth: '100px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
     {
        name: '	Sub Department',
        selector: row => row.subDepartment ,
        textAlign:'center',
        minWidth: '110px',
        maxWidth: '110px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'wrap',
          display:'flex',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Designation',
        selector: row => row.Designation ,
        minWidth: '90px',
        maxWidth: '90px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
      name: 'Comp Off Date',
      selector: row => row.CompOffDate ,
      minWidth: '100px',
      maxWidth: '100px',
      style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'In Time',
        selector: row => row.InTime ,
        minWidth: '65px',
        maxWidth: '65px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Out Time',
        selector: row => row.OutTime ,
        minWidth: '78px',
        maxWidth: '78px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Action By',
        selector: row => row.ActionBy ,
        minWidth: '118px',
        maxWidth: '118px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Type',
        selector: row => row.Type ,
        minWidth: '78px',
        maxWidth: '78px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Approved Date',
        selector: row => row.ApprovedDate ,
        minWidth: '100px',
        maxWidth: '100px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },  	
    {
        name: 'Expiry Date',
        selector: row => row.ExpiryDate ,
        minWidth: '90px',
        maxWidth: '90px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Approval Status',
        selector: row => row.ApprovalStatus ,
        minWidth: '90px',
        maxWidth: '90px',
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    {
        name: 'Action',
        selector: row => row.Action ,
        minWidth: '55px',
        maxWidth: '55px',
        
        style: {
          fontSize:'12px',
          textAlign: 'center',
          padding: '5px 8px',
          wrap:'nowrap',
          borderRight: '1px solid #ddd',
          background:'#fff',
      },
    },
    
];
const data = [
    {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    },
        {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    },
     {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    },
     {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    }, {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    }, {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    }, {
        Employee: 'SomeOne',
        SubCompany:'Bright Future',
        BranchName:'Enco',
        Department:'',
        subDepartment:'Cloud Computing',
        Designation:'',
        CompOffDate:'05-02-2023',
        InTime:'10:00',
        OutTime:'07:00',
        ActionBy:'Vijoyeta Biswas-Approved',
        Type:'02:00 PM',
        ApprovedDate:'08-02-2023',
        Type:'Half day',
        ExpiryDate:'08-02-2023',
        ApprovalStatus:'Approved',
        Action:'',
    },
];
const customStyles = {
  headCells: {
    style: {
      fontSize:'11px',
      fontWeight:'bold',
      padding: '5px 8px',
      border: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
      background:'#25cacc',
      color:'white',
      textAlign:'center' // Adjust the padding value as per your needs
    },
  },
};

  return (
    <div className='main-a'>
     <div className='side-bar-multi'>
      <div className='itm'><h5 className='ltr-at'>PERSONAL</h5></div>
      <div className='itm'><h5 className='ltr-at'>SALON / SPA</h5></div>
      <div className='itm'><h5 className='ltr-at'>FOS</h5></div>
      <div className='itm'><h5 className='ltr-at'>ACCOUNTS</h5></div>
      <div className='itm'><h5 className='ltr-at'>AVENGERS.CO.IN</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-BRANCH</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-HO</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR EMPLOYEE</h5></div>
    </div>
      <div className='contai'>
        <NavBar/>
          {/* -----grey bar----- */}
        <div className='home-a'>
    <div >
     <p><FaHome/> Home / All Comp Off Attendance Approve

     </p>
    </div>
    <div className='icon-a'>
    <MdOutlineCircle/>
    <MdOutlineCircle/>
    <MdOutlineSquare />
    <TfiReload/>  
    </div>
  </div>
    {/* -----white bar----- */}
  <div className='xcel-a'>
    <div >
     <p>
     </p>
    </div>
    <div className='icon-a'>
    <BsSearch/>
    </div>
  </div>
  {/* ---- Table----- */}
  <div className='table-a'>
   
    <div className='table-wrapper-a'>
    <div className='table media-scroller-a'>
      <div className='upper-data-a'>
        {/* -----left----- */}
      <div className='left-side-a'>
        <h4 className='month'>ATTENDANCE OF {(selectedMonth.label.toUpperCase())}, {selectedYear.label}</h4>
      </div>
    
   {/* -----Right----- */}
   <div className='right-side-a'>
      <Select className='select-ep-a'
      placeholder='Month'
        defaultValue={selectedMonth}
        onChange={setSelectedMonth}
        options={monthOptions}
      />
      <Select className='select-ep-a'
       placeholder='Year'
        defaultValue={selectedYear}
        onChange={setSelectedYear}
        options={yearOptions}
      />
    </div>
    </div>
    <div className='tb'>
    <DataTable className='dattb'
            columns={columns}
            data={data}
            customStyles={customStyles}
            fixedHeader
            selectableRows
		        dense
            pagination
        />
        </div>
        </div>
        </div>
  </div>
  </div>
    </div>
  )
}

export default ApproveAttendance
