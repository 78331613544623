
import React from 'react'
import NavBar from './Nav';
import DataTable from 'react-data-table-component';
import { MdOutlineCircle ,MdOutlineSquare } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { FaHome} from 'react-icons/fa';
import { BsSearch ,BsFillEyeFill} from 'react-icons/bs';
import { RiFileExcel2Fill } from 'react-icons/ri';
import Select from 'react-select';
import {useState, useEffect} from 'react'
import './Css/ExtraDuty.css'
import { AiFillPrinter ,AiOutlineReload} from 'react-icons/ai';
import { HiArrowUp,HiArrowDown} from 'react-icons/hi';
import { TbPlayerTrackNextFilled ,TbPlayerTrackPrevFilled} from 'react-icons/tb';




const ExtraDuty = () => {
  const [numRows, setNumRows] = useState(10);

  const handleNumRowsChange = (event) => {
    setNumRows(parseInt(event.target.value));
  };
    const [selectedMonth, setSelectedMonth] = useState({
        value: String((new Date()).getMonth() + 1).padStart(2, '0'), 
        label: (new Date()).toLocaleString('default', { month: 'long' })
     });
    const [selectedYear, setSelectedYear] = useState({
       value: `${(new Date()).getFullYear()}`, label: `${(new Date()).getFullYear()}`,
     }); 
     const monthOptions = [...new Array(12)].map(x => 0).map((x, i) => x + i + 1).map(x => {
    const date = new Date()
    date.setMonth(x - 1)
    return {
        value: String(x).padStart(2, '0'),
        label: date.toLocaleString('default', { month: 'long' })
    }
})
const yearOptions = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

  return (
    <div className='main-ap'>
     <div className='side-bar-multi-ap'>
      <div className='itm'><h5 className='ltr-at'>PERSONAL</h5></div>
      <div className='itm'><h5 className='ltr-at'>SALON / SPA</h5></div>
      <div className='itm'><h5 className='ltr-at'>FOS</h5></div>
      <div className='itm'><h5 className='ltr-at'>ACCOUNTS</h5></div>
      <div className='itm'><h5 className='ltr-at'>AVENGERS.CO.IN</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-BRANCH</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-HO</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR EMPLOYEE</h5></div>
    </div>
      <div className='contai-ap'>
        <NavBar/>
          {/* -----grey bar----- */}
        <div className='home-a'>
    <div >
     <p style={{fontSize:'14px'}}><FaHome/> Home /  All Extra Duty Attendance Approve

     </p>
    </div>
    <div className='icon-a'>
    <MdOutlineCircle/>
    <MdOutlineCircle/>
    <MdOutlineSquare />
    <TfiReload/>  
    </div>
  </div>
    {/* -----white bar----- */}
  <div className='xcel-a'>
    <div >
     <p>
     </p>
    </div>
    <div className='icon-a'>
    <BsSearch/>
    </div>
  </div>
  {/* ---- Table----- */}
  <div className='table-a'>
   
    <div className='table-wrapper-a'>
    <div className='table media-scroller-a'>
      
    <div className='tab-ap'>
        <div className='ex-text-in'>
          <div className='ex-txtt-in'>
    <h5><span className='text-ap-'>ALL EXTRA DUTY ATTENDANCE APPROVE OF {(selectedMonth.label.toUpperCase())}, {selectedYear.label}</span></h5>
      
      </div>
    <div className='ex-inpt' >
        <Select className='select-ep-ap'
      placeholder='Month'
        defaultValue={selectedMonth}
        onChange={setSelectedMonth}
        options={monthOptions}
      />
      <Select className='select-ep-ap'
       placeholder='Year'
        defaultValue={selectedYear}
        onChange={setSelectedYear}
        options={yearOptions}
      />
      <select id="numRows" value={numRows} onChange={handleNumRowsChange} style={{border:'1.5px solid #50a3a5',padding:'2px' ,borderRadius:'5px',fontSize:'12px',color:'#50a3a5',maxHeight:'30px',minHeight:'30px'}}>
        <option value="" disabled hidden>
          Show
        </option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
    </div>
    <div className='tb-of-app'>
     <table className='tab-of-ap' >
      <thead className='thead-ap' style={{background:'#25cacc',color:'white'}}>
        <tr>
        <th className='ch-exp'>Sl No</th>
        <th className='emp-name-ap' style={{position: 'sticky',top:0,left:0,background: '#25cacc',zIndex: 1, borderRight:'1px solid #50a3a5',}}>Employee Name <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='emp-code-ap'>Employee Code <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='sub-com-ap'>Sub Company <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='typ-ap'>Branch <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='dpt-ap'>Department <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='sub-dpt-ap'>Sub Department <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='desig-ap'>Designation</th>
        <th className='branch-ap'>Type</th>  
        <th className='comp-dt-ap'>Extra Duty Date</th>
        <th className='leave-ap'>In-Time</th> 
        <th className='days-ap'>Out-Time</th>
        <th className='actn-by-ap'>Action By</th>
        <th className='branch-ap'>Type</th>  
        <th className='apprv-ap'>Approve Date</th>
        <th className='apprv-ap'>Approval Status</th>
        <th className='ctn-ap'>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className='pd-ap'>1</td>
         <td className='pd-ap' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-ap'>25125</td>
        <td className='pd-ap'>Sub Company</td>
        <td className='pd-ap'>Birati</td>
        <td className='pd-ap'>Department</td>
        <td className='pd-ap'>Bright Future</td>
        <td className='pd-ap'>Designation</td>
        <td className='pd-ap'>White Collar</td>
        <td className='pd-ap'>08-01-2023</td>
        <td className='pd-ap'>10:00</td>   
        <td className='pd-ap'>19:00</td>
        <td className="pd-ap">Vijoyeta Biswas- Approved</td> 
        <td className='pd-ap'>Half Day</td>
        <td className='pd-ap'>24-03-2023</td>
        <td className='pd-ap'>Approved</td>
        <td className='pd-ap'><BsFillEyeFill className='pd-ey-ap' size='12.5px'/></td>
        </tr>
       <tr>
        <td className='pd-ap'>1</td>
         <td className='pd-ap' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-ap'>25125</td>
        <td className='pd-ap'>Sub Company</td>
        <td className='pd-ap'>Birati</td>
        <td className='pd-ap'>Department</td>
        <td className='pd-ap'>Bright Future</td>
        <td className='pd-ap'>Designation</td>
        <td className='pd-ap'>White Collar</td>
        <td className='pd-ap'>08-01-2023</td>
        <td className='pd-ap'>10:00</td>   
        <td className='pd-ap'>19:00</td>
        <td className="pd-ap">Vijoyeta Biswas- Approved</td> 
        <td className='pd-ap'>Half Day</td>
        <td className='pd-ap'>24-03-2023</td>
        <td className='pd-ap'>Approved</td>
        <td className='pd-ap'><BsFillEyeFill className='pd-ey-ap' size='12.5px'/></td>
        </tr>
       <tr>
        <td className='pd-ap'>1</td>
         <td className='pd-ap' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-ap'>25125</td>
        <td className='pd-ap'>Sub Company</td>
        <td className='pd-ap'>Birati</td>
        <td className='pd-ap'>Department</td>
        <td className='pd-ap'>Bright Future</td>
        <td className='pd-ap'>Designation</td>
        <td className='pd-ap'>White Collar</td>
        <td className='pd-ap'>08-01-2023</td>
        <td className='pd-ap'>10:00</td>   
        <td className='pd-ap'>19:00</td>
        <td className="pd-ap">Vijoyeta Biswas- Approved</td> 
        <td className='pd-ap'>Half Day</td>
        <td className='pd-ap'>24-03-2023</td>
        <td className='pd-ap'>Approved</td>
        <td className='pd-ap'><BsFillEyeFill className='pd-ey-ap' size='12.5px'/></td>
        </tr>
       <tr>
        <td className='pd-ap'>1</td>
         <td className='pd-ap' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-ap'>25125</td>
        <td className='pd-ap'>Sub Company</td>
        <td className='pd-ap'>Birati</td>
        <td className='pd-ap'>Department</td>
        <td className='pd-ap'>Bright Future</td>
        <td className='pd-ap'>Designation</td>
        <td className='pd-ap'>White Collar</td>
        <td className='pd-ap'>08-01-2023</td>
        <td className='pd-ap'>10:00</td>   
        <td className='pd-ap'>19:00</td>
        <td className="pd-ap">Vijoyeta Biswas- Approved</td> 
        <td className='pd-ap'>Half Day</td>
        <td className='pd-ap'>24-03-2023</td>
        <td className='pd-ap'>Approved</td>
        <td className='pd-ap'><BsFillEyeFill className='pd-ey-ap' size='12.5px'/></td>
        </tr>
       <tr>
        <td className='pd-ap'>1</td>
         <td className='pd-ap' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-ap'>25125</td>
        <td className='pd-ap'>Sub Company</td>
        <td className='pd-ap'>Birati</td>
        <td className='pd-ap'>Department</td>
        <td className='pd-ap'>Bright Future</td>
        <td className='pd-ap'>Designation</td>
        <td className='pd-ap'>White Collar</td>
        <td className='pd-ap'>08-01-2023</td>
        <td className='pd-ap'>10:00</td>   
        <td className='pd-ap'>19:00</td>
        <td className="pd-ap">Vijoyeta Biswas- Approved</td> 
        <td className='pd-ap'>Half Day</td>
        <td className='pd-ap'>24-03-2023</td>
        <td className='pd-ap'>Approved</td>
        <td className='pd-ap'><BsFillEyeFill className='pd-ey-ap' size='12.5px'/></td>
        </tr>
       
        </tbody>
     </table>
     </div>
  <div className='con-pg-ta'>
  <div className='btm-ta'>
    <>
    </>
  </div>
  <nav aria-label="Page navigation example sfddf" >
  <ul class="pagination">
    <li class="page-item"> 
      <a class="page-link" href="#" aria-label="Previous">
        <span class="sr-only"><TbPlayerTrackPrevFilled size='10px'/></span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span class="sr-only"><TbPlayerTrackNextFilled size='10px'/></span>
      </a>
    </li>
  </ul>
</nav>
  </div>
  </div>
        </div>
        </div>
        </div>
  </div>
  </div>
  )
}

export default ExtraDuty
