import React from 'react'
import NavBar from '../Nav';
import DataTable from 'react-data-table-component';
import { MdOutlineCircle ,MdOutlineSquare } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { FaHome} from 'react-icons/fa';
import { BsSearch ,BsFillEyeFill} from 'react-icons/bs';
import './CompensatoryOff.css'
import Select from 'react-select';
import {useState, useEffect} from 'react'
import { AiFillPrinter ,AiOutlineReload} from 'react-icons/ai';
import { HiArrowUp,HiArrowDown} from 'react-icons/hi';
import { TbPlayerTrackNextFilled ,TbPlayerTrackPrevFilled} from 'react-icons/tb';


const CompensatoryOff  = () => {
   const [numRows, setNumRows] = useState(10);

  const handleNumRowsChange = (event) => {
    setNumRows(parseInt(event.target.value));
  };
  return (
     <div className='main-a-co'>
     <div className='side-bar-multi'>
      <div className='itm'><h5 className='ltr-at'>PERSONAL</h5></div>
      <div className='itm'><h5 className='ltr-at'>SALON / SPA</h5></div>
      <div className='itm'><h5 className='ltr-at'>FOS</h5></div>
      <div className='itm'><h5 className='ltr-at'>ACCOUNTS</h5></div>
      <div className='itm'><h5 className='ltr-at'>AVENGERS.CO.IN</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-BRANCH</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-HO</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR EMPLOYEE</h5></div>
    </div>
      <div className='contai-co'>
        <NavBar/>
          {/* -----grey bar----- */}
        <div className='home-a'>
    <div >
     <p style={{fontSize:'14px'}}><FaHome/> Home / Approve Compensatory Off Leave

     </p>
    </div>
    <div className='icon-a'>
    <MdOutlineCircle/>
    <MdOutlineCircle/>
    <MdOutlineSquare />
    <TfiReload/>  
    </div>
  </div>
    {/* -----white bar----- */}
  <div className='xcel-a'>
    <div >
     <p style={{color:'#858585',fontSize:'14px'}}>
     </p>
    </div>
    <div className='icon-a'>
    <BsSearch/>
    <AiFillPrinter/>
    </div>
  </div>
  {/* ---- Table----- */}
  <div className='tab-sec-co'>
    <div className='tab-co'>
      <div className='co-tx-se'>
    <h5><span className='text-co-' style={{fontSize:'1rem'}}>EMPLOYEE RECENT COMPENSATORY OFF LEAVE REQUEST</span></h5>
  <select id="numRows" value={numRows} onChange={handleNumRowsChange} style={{border:'1.5px solid #50a3a5',padding:'2px' ,borderRadius:'5px',fontSize:'12px',color:'#50a3a5',maxHeight:'30px',minHeight:'30px'}}>
        <option value="" disabled hidden>
          Show
        </option>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      </div>
    <div className='tb-of-co' style={{border:'1px solid #ddd',margin:'5px 20px'}}>
     <table className='tab-of-co' >
      <thead className='thead-co' style={{background: '#25cacc',color:'white'}}>
        <tr>
        <th className='sl-co'>Sl No</th>
        <th className='apld-dt-co'>Applied Date <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='leave-co'>Leave Date <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='emp-name-co' style={{position: 'sticky',top:0,left:0,background: '#25cacc',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Employee Name <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='emp-code-co'>Employee Code <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='sub-com-co'>Sub Company <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='typ-co'>Branch <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='dpt-co'>Department <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='sub-dpt-co'>Sub Department <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='desig-co'>Designation <HiArrowUp style={{color:'#fff'}} size='10px'/><HiArrowDown style={{color:'#fff'}} size='10px'/></th>
        <th className='branch-co'>Type</th>
        <th className='days-co'>Days</th>
        <th className='actn-by-co'>Action By</th>
        <th className='apprv-co'>Approval Status</th>
        <th className='ctn-co'>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className='pd-co'>1</td>
        <td className='pd-co'>13-08-2022</td>
        <td className='pd-co'>27-07-2022 - 27-07-2022</td>
        <td className='pd-co' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-co'>25125</td>
        <td className='pd-co'>Sub Company</td>
        <td className='pd-co'>Birati</td>
        <td className='pd-co'>Department</td>
        <td className='pd-co'>Bright Future</td>
        <td className='pd-co'>Designation</td>
        <td className="pd-co">White Collar</td> 
        <td className='pd-co'>1</td>
        <td className='pd-co'>R1-Amit Prasad - Approved</td>
        <td className='pd-co'>Approved</td>
        <td className='pd-co'><BsFillEyeFill className='pd-ey-co' size='12.5px'/><AiOutlineReload size='12.5px'/></td>
        </tr>
        <tr>
        <td className='pd-co'>1</td>
        <td className='pd-co'>13-08-2022</td>
        <td className='pd-co'>27-07-2022 - 27-07-2022</td>
        <td className='pd-co' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-co'>25125</td>
        <td className='pd-co'>Sub Company</td>
        <td className='pd-co'>Birati</td>
        <td className='pd-co'>Department</td>
        <td className='pd-co'>Bright Future</td>
        <td className='pd-co'>Designation</td>
        <td className="pd-co">White Collar</td> 
        <td className='pd-co'>1</td>
        <td className='pd-co'>R1-Amit Prasad - Approved</td>
        <td className='pd-co'>Approved</td>
        <td className='pd-co'><BsFillEyeFill className='pd-ey-co' size='12.5px'/><AiOutlineReload size='12.5px'/></td>
        </tr>
        <tr>
        <td className='pd-co'>1</td>
        <td className='pd-co'>13-08-2022</td>
        <td className='pd-co'>27-07-2022 - 27-07-2022</td>
        <td className='pd-co' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-co'>25125</td>
        <td className='pd-co'>Sub Company</td>
        <td className='pd-co'>Birati</td>
        <td className='pd-co'>Department</td>
        <td className='pd-co'>Bright Future</td>
        <td className='pd-co'>Designation</td>
        <td className="pd-co">White Collar</td> 
        <td className='pd-co'>1</td>
        <td className='pd-co'>R1-Amit Prasad - Approved</td>
        <td className='pd-co'>Approved</td>
        <td className='pd-co'><BsFillEyeFill className='pd-ey-co' size='12.5px'/><AiOutlineReload size='12.5px'/></td>
        </tr>
        <tr>
        <td className='pd-co'>1</td>
        <td className='pd-co'>13-08-2022</td>
        <td className='pd-co'>27-07-2022 - 27-07-2022</td>
        <td className='pd-co' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-co'>25125</td>
        <td className='pd-co'>Sub Company</td>
        <td className='pd-co'>Birati</td>
        <td className='pd-co'>Department</td>
        <td className='pd-co'>Bright Future</td>
        <td className='pd-co'>Designation</td>
        <td className="pd-co">White Collar</td> 
        <td className='pd-co'>1</td>
        <td className='pd-co'>R1-Amit Prasad - Approved</td>
        <td className='pd-co'>Approved</td>
        <td className='pd-co'><BsFillEyeFill className='pd-ey-co' size='12.5px'/><AiOutlineReload size='12.5px'/></td>
        </tr>
        <tr>
        <td className='pd-co'>1</td>
        <td className='pd-co'>13-08-2022</td>
        <td className='pd-co'>27-07-2022 - 27-07-2022</td>
        <td className='pd-co' style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1px solid #c8c8c8',}}>Amit Prasad</td>
        <td className='pd-co'>25125</td>
        <td className='pd-co'>Sub Company</td>
        <td className='pd-co'>Birati</td>
        <td className='pd-co'>Department</td>
        <td className='pd-co'>Bright Future</td>
        <td className='pd-co'>Designation</td>
        <td className="pd-co">White Collar</td> 
        <td className='pd-co'>1</td>
        <td className='pd-co'>R1-Amit Prasad - Approved</td>
        <td className='pd-co'>Approved</td>
        <td className='pd-co'><BsFillEyeFill className='pd-ey-co' size='12.5px'/><AiOutlineReload size='12.5px'/></td>
        </tr>
       
      </tbody>
     </table>
     </div>
          <div className='con-pg-ca'>
  <div className='btm-ta'>
    <>
    </>
  </div>
  <nav aria-label="Page navigation example sfddf" >
  <ul class="pagination">
    <li class="page-item"> 
      <a class="page-link" href="#" aria-label="Previous">
        <span class="sr-only"><TbPlayerTrackPrevFilled size='10px'/></span>
      </a>
    </li>
    <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item">
      <a class="page-link" href="#" aria-label="Next">
        <span class="sr-only"><TbPlayerTrackNextFilled size='10px'/></span>
      </a>
    </li>
  </ul>
</nav>
  </div>
  </div>
  </div>
  </div>
  
  </div>
  )
}

export default CompensatoryOff
