import React from 'react'
import { useState } from 'react';
import {useEffect} from 'react'
import { TbPlayerTrackNextFilled ,TbPlayerTrackPrevFilled} from 'react-icons/tb';
import { MdOutlineCircle ,MdOutlineSquare } from 'react-icons/md';
import { TfiReload } from 'react-icons/tfi';
import { FaHome , FaFileDownload,FaSort } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { RxCrossCircled } from 'react-icons/rx';
import { HiArrowSmLeft ,HiArrowNarrowRight,HiArrowUp,HiArrowDown} from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineReload , AiFillPrinter ,AiFillFlag,AiOutlineFlag} from 'react-icons/ai';
import { BsSearch,BsCheckLg,BsExclamationLg,BsFillCalendarXFill } from 'react-icons/bs';
import './Css/MultiAttendance.css'
import Select from 'react-select';
import NavBar from './Nav';
import { BsPersonVcardFill , BsFillPersonFill} from 'react-icons/bs';
import { MdNotifications } from 'react-icons/md';
import { AiOutlineMenu } from 'react-icons/ai';
import { FaComment } from 'react-icons/fa';

const MultiAttendance = () => {
    const [numRows, setNumRows] = useState(10);

  const handleNumRowsChange = (event) => {
    setNumRows(parseInt(event.target.value));
  };
  const [width, setWidth] = useState('85%');
    const [checkboxes, setCheckboxes] = useState([]);
    const [data, setData] = useState([])
    const [showColor,setShowColor]=useState(false)
    const [showEmployee,setShowEmployee]=useState(false)
        const [showNav,setShowNav]=useState(true)
    const [selectedNum, setSelectedNum] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [action, setAction] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dates, setDates] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState({
        value: String((new Date()).getMonth() + 1).padStart(2, '0'), 
        label: (new Date()).toLocaleString('default', { month: 'long' })
     });
    const [selectedYear, setSelectedYear] = useState({
       value: `${(new Date()).getFullYear()}`, label: `${(new Date()).getFullYear()}`,
     }); 

//  calculate the total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  
  // calculate the indexes of the first and last items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
  
  // handle page click event
  const handlePageClick = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  }
  const numOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
];
  const actionOption = [
  { value: 'Mark as full day', label: 'Mark as full day' },
  { value: 'Mark as half day', label: 'Mark as half day' },
  { value: 'Reject attendance', label: 'Reject attendance' },
  { value: 'Mark as holiday', label: 'Mark as holiday' },
  { value: 'Edit attendance', label: 'Edit attendance' },
  { value: 'Reset holiday', label: 'Reset holiday' },
  { value: 'Mark as comp off full day', label: 'Mark as comp off full day' },
  { value: 'Mark as comp off half day', label: 'Mark as comp off half day' },
];

// Figure out ways to generate month options dynamically instead of hard coding them.
const monthOptions = [...new Array(12)].map(x => 0).map((x, i) => x + i + 1).map(x => {
    const date = new Date()
    date.setMonth(x - 1)
    return {
        value: String(x).padStart(2, '0'),
        label: date.toLocaleString('default', { month: 'long' })
    }
})

const yearOptions = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

     useEffect(() => {
      const daysInMonth = new Date(selectedYear.value,selectedMonth.value,0).getDate();
      const newDates = Array.from({ length: daysInMonth }, (_, i) => {
      const day = new Date(
        selectedYear.value,
        selectedMonth.value - 1,
        i + 1
      ).toLocaleString("en-us", {
        weekday: "long",
      });
      const date = i + 1;
      return { day, date };
    });
    setDates(newDates);      
      
    const url = "https://eveserver.ind.in/hr/reports/attendance/employee/list";

        const postData = async () => {
            try {
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    Accept: 'application.json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    "year": selectedYear.value,
                    "month": selectedMonth.value,
                    "pageNo": currentPage,
                    "user_cpanelid": "59",
                    "pageType": "all-multiple-approve-attendance"
                }),
                  cache: 'default'
                });
                const returnValue = await response.json();
                const value = returnValue.employee.map(item => {
                 return {
                    Sno: `${item.slno}`,
                    EmployeeName: `${item.empName}`,
                    EmployeeId: `${item.empId}`,
                    EmployeeCode:`${item.empCode}`,
                    Branch:`${item.branchName}`,
                    employeeDOJ:`${item.employeeDOJ}`,
                    subCompanyName:`${item.subCompanyName}`,
                    branchId:`${item.branchId}`,
                    totalWorkingHour:`${item.totalWorkingHour}`,
                    Present:`${item.totalFullDay}`,
                    HalfDay:`${item.totalHalfDay}`,
                    paidLeave:`${item.totalPaidLeave}`,
                    unpaidLeave:`${item.totalUnpaidLeave}`,
                    AppDetails: item.appDetails,
                 } 
                })
                setData(value)

            } catch (error) {
                console.log("error", error);
            }
        };
  
        postData();
    }, [selectedMonth, selectedYear,currentPage, itemsPerPage]);

  // Function to handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  // Function to handle checkbox change in the header
  const handleHeaderCheckboxChange = () => {
    const allChecked = checkboxes.every((checkbox) => checkbox);
    const updatedCheckboxes = data.map(() => !allChecked);
    setCheckboxes(updatedCheckboxes);
  };
  useEffect(() => {
    setCheckboxes(data.map(() => false));
  }, [data]);
  
  const handleWidth= () => {
    setWidth(width === '85%' ? '100%' : '85%');
  };

 return (
  <>
  <div className='navv'>
    <header className='navbar'>
      <div style={{minWidth:'15%',textAlign:'center'}} className='eve----'>EVE</div>
        <div className='navbar__title navbar__item thre-dt'><AiOutlineMenu size='25px' style={{color:'white',fontWeight:'bold',}} onClick={()=>{setShowNav(!showNav)}}/></div>
        <div className='navbar__item'><BsPersonVcardFill/></div>
        <div className='navbar__item'><MdNotifications/></div>
        <div className='navbar__item'><FaComment/></div>
        <div className='navbar__item'><BsFillPersonFill/> <span className='nam'>Pravjot Singh</span></div>        
    </header>
    </div>
  <div className='container-atten'>
     
    {/* showNav,setShowNav */}
    {  
                showNav &&   <div className='side-bar-multi'>
      <div className='itm'><h5 className='ltr-at'>PERSONAL</h5></div>
      <div className='itm'><h5 className='ltr-at'>SALON / SPA</h5></div>
      <div className='itm'><h5 className='ltr-at'>FOS</h5></div>
      <div className='itm'><h5 className='ltr-at'>ACCOUNTS</h5></div>
      <div className='itm'><h5 className='ltr-at'>AVENGERS.CO.IN</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-BRANCH</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR-HO</h5></div>
      <div className='itm'><h5 className='ltr-at'>HR EMPLOYEE</h5></div>
    </div>
   } 
    <div className='other-con' style={{ width: showNav ? '85%' : '100%' }}>
  {/* <button onClick={()=>{setShowNav(!showNav)}}>klnrgsbbef</button> */}
  {/* -----grey bar----- */}
  <div className='home'>
    <div className='home-text'>
     <p className='home-text'><FaHome/><span className='multtxt'> Home / Multiple Approve Attendance</span>
     </p>
    </div>
    <div className='icon'>
    <MdOutlineCircle/>
    <MdOutlineCircle/>
    <MdOutlineSquare />
    <TfiReload/>  
    </div>
  </div>
  {/* -----white bar----- */}
  <div className='xcel'>
    <div className='left-s'>
      <h4 className='month-at'>{(selectedMonth.label.toUpperCase())}, {selectedYear.label}</h4>
    </div>
    <div className='middle-si'>
      <div className='con-date-box'>

        {dates.map((date, index) => (
                  
                    <div className='date-box'>{date.date}</div>
 
                    ))} 
      </div>
    </div>
    <div className='right-s'>
       <Select className='select-ep'
      placeholder='Month'
        defaultValue={selectedMonth}
        onChange={setSelectedMonth}
        options={monthOptions}
      />
      <Select className='select-eppp'
       placeholder='Year'
        defaultValue={selectedYear}
        onChange={setSelectedYear}
        options={yearOptions}
      /> 
       <button className='btn-col' onClick={()=>{setShowColor(!showColor)}}>Index</button>
      <div className='icon'>
    <BsSearch/>
    <RiFileExcel2Fill />
    <AiFillPrinter/>
    </div>
    </div>
  </div>

      {/* <div className='ppp'>
     <p className='text-ppp'> All Multiple Approve Attendance
     </p>
    </div>
     */}
   
   
  {/* -----table----- */}
  <div className='table-multi'>
    {
      showColor && 
      <div className='colr-ind'>
        <div className='top--'>
          <div className='tp-l'>
             <div className='sec'>
           <div className='colr'></div>
           <div>Fullday</div>
         </div>
         <div className='sec'>
           <div className='halfday'></div>
           <div>Halfday</div>
         </div>
         <div className='sec'>
           <div className='absent'></div>
         <div>Absent</div>
         </div>
         <div className='sec'>
           <div className='off-day'></div>
           <div> Off Day</div>
         </div>
         
          </div>
          {/*  */}
          <div className='tp-r'>
             <div className='sec'>
           <div className='CasuaLeave'></div>
           <div> Casual Leave</div>
         </div>
         <div className='sec'>
           <div className=' CompOff'></div>
           <div>Comp Off</div>
         </div>
         <div className='sec'>
           <div className='Covidleave'></div>
           <div>Covid Isolation leave</div>
         </div>
         <div className='sec'>
           <div className='earned'></div>
           <div> Earned Leave / Paid leave</div>
         </div>
         <div className='sec'>
          <div className='pater'></div>
          <div> Paternity Leave </div>
        </div>
        <div className='sec'>
          <div className=' Sick'></div>
          <div>Sick Leave</div>
        </div>
        
          </div>
        </div>
        {/* btm */}
        <div className='bt--'>
          <div className='up-bt'><div className='sec'>
           <div className='holiday'></div>
           <div> Holiday</div>
         </div>
         <div className='sec'>
           <div className='marked'></div>
           <div> Marked as Holiday</div>
         </div></div>
         <div className='updt'>
          <div className='sec'>
          <div className='specil'></div>
          <div>Special Leave</div>
        </div>
       <div className='sec'>
           <div className='mater'></div>
           <div>  Maternity Leave</div>
         </div>
         <div className='sec'>
           <div className='nominal'></div>
           <div> Nominal Leave</div>
         </div>
         <div className='sec'>
          <div className='defaul'></div>
         <div> Default Paid Leave</div>
        </div>
        <div className='sec'>
          <div className='halfpay'></div>
         <div>  Halfday Paid Leave</div>
       </div>
       <div className='sec'>
         <div className='halfup'></div>
         <div>Halfday Unpaid Leave</div>
       </div>
       <div className='sec'>
         <div className='unpy'></div>
         <div>Unpaid Leave</div>
       </div>
          </div>
       </div>        
      </div>
    }
    <div className='bz'>
      <div className='lft--'></div>
      <div className='rgt--'></div>
    </div>
    
    <div className='whitespace'>
          

    <div className='table-wrapper'>
        {dates.length > 0 && (
      <table i d="setclone" className='table table-hover table-striped table-bordered table-condensed media-scroller' cellpadding="0" cellspacing="0" border="1" width="100%">
        <thead >
            <tr>
              <th rowspan="2" className='check--'>
									 <input
              type="checkbox"
              checked={checkboxes.every((checkbox) => checkbox)}
              onChange={handleHeaderCheckboxChange}
            />
								</th>
              <th className='sno'style={{borderRight:' 1.7px solid #c8c8c8'}}>Sl No.</th>
              <th className='name'style={{position: 'sticky',top:0,left:0,background: 'white',zIndex: 1, borderRight:'1.7px solid #c8c8c8',}}>
                Employee <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/>
                </th>
              <div className='shw-em' onClick={()=>{setShowEmployee(!showEmployee)}}>
                
                {showEmployee? (
                  
        <HiArrowSmLeft size='20px' onClick={() => setShowEmployee(false)}/>
      ) : (
        <HiArrowNarrowRight size='20px' onClick={() => setShowEmployee(true)}/>
      )}          
                </div>               
              {  
                showEmployee &&  <div className='shw-hd' >
                  <h6 className='emplye'>Employee Details</h6>
              <th className='emp-code' style={{borderLeft:'0px' ,borderBottom:'0px'}} >Employee Code <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='loc-hh' style={{borderLeft:'0px' ,borderBottom:'0px'}}>Branch <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='sub-cc' style={{borderLeft:'0px' ,borderBottom:'0px'}}>Sub Company <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='sub-cc' style={{borderLeft:'0px' ,borderBottom:'0px'}}>Department <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='sub-cc' style={{borderLeft:'0px' ,borderBottom:'0px'}}>Sub Department <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='sub-cc' style={{borderLeft:'0px' ,borderBottom:'0px'}}>Location <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              <th className='sub-ccc'style={{borderRight:'0px' ,borderBottom:'0px'}}>Type <HiArrowUp style={{color:'#26cacc'}} size='13px'/><HiArrowDown style={{color:'#26cacc'}} size='13px'/></th>
              </div>
              }
                {dates.map((date, index) => (
                  <th className='day-date'> 
                    <div className='date-day'>{date.day}</div>
                    <div className='date--'>{date.date}</div>
                    <div className='ch-bo'><input type='checkbox'/></div>
                  <div className='inn-ou'>
                    <div className='in-outt'>In</div><div className='ou'>Out</div>
                  </div>
                </th>
                    ))}              
              <th className='actn'>Action</th>  
              <th className='twh'>Total Working Hour</th>
              <th className='pre'>Present</th>
              <th className='half'>Half Day</th>
              <th className='half'>Absent</th>
              <th className='paid'>Paid Leave</th>
              <th className='unpaid'>Unpaid Leave</th>
              <th className='half'>Total Days</th>
              <th className='half'>Week Off</th>
              <th className='half'>Holidays</th>
              <th className='half'>Earned Days</th>
              <th className='half'>Overtime Hours</th>
            </tr>
        </thead>
        <tbody>
         
         {data.map((item, index) => {
          return(
            <tr key={index}>
              <td className="check--">
										<input
                type="checkbox"
                checked={checkboxes[index] || false}
                onChange={() => handleCheckboxChange(index)}
              />
									</td>               
              <td className='s-no'>{item.Sno}</td>   
              <td className='e-name '  style={{ position: 'sticky', left: '-5px', background: 'white',borderLeft: '1px solid #333'}}>{item.EmployeeName}</td>
              <div className='hid-d'></div>
              {
                showEmployee &&  <div className='sh-dt'> 
              <td className='emp-cod' style={{    borderRight:' 1px solid #757070'}}>{item.EmployeeCode}</td>
              <td className='loc-h' style={{    borderRight:' 1px solid #757070'}}>{item.Branch}</td>
              <td className='sub-c' style={{    borderRight:' 1px solid #757070'}}>{item.subCompanyName}</td>
              <td className='sub-c' style={{    borderRight:' 1px solid #757070'}}>{item.Branch}</td>
              <td className='sub-c' style={{    borderRight:' 1px solid #757070'}}>{item.Branch}</td>
              <td className='sub-c' style={{    borderRight:' 1px solid #757070'}}>{item.Branch}</td>
              <td className='sub-c'>{item.Branch}</td>
              </div>
         }
                 {
                   item.AppDetails.map((i, appIndex) => {
          return(
                <td key={appIndex} 
                style={{
        backgroundColor: `${i.backColor}`
      }} className='aten'>
                  <div className='atent'>
                    <div className='ch-bo'><input type='checkbox'/></div>
                    <div className='flex--'><div className='innn'>{i.inTime}</div><div className='innnn'>{i.outTime}</div></div>
                  </div>
                </td>
              )})}
              <td className='actn'><div className='ixo'>
                <div className='icon-dv'><BsCheckLg size='10px' className='icon--'/><BsExclamationLg size='10px' className='icon--'/><RxCrossCircled size='10px' className='icon--'/><BsFillCalendarXFill size='10px' className='icon--'/></div>
                <div className='icon-dv'><BiEdit size='10px' className='icon--'/><AiOutlineReload size='10px' className='icon--'/><AiFillFlag  size='10px' className='icon--'/><AiOutlineFlag size='10px' className='icon--'/></div>
              </div></td>
              <td className='twh--'>{item.totalWorkingHour}</td>
              <td className='pre'>{item.Present}</td>
              <td className='half'>{item.HalfDay}</td>
              <td className='half'>{item.totalWorkingHour}</td>
              <td className='paid'>{item.paidLeave}</td>
              <td className='unpaid'>{item.unpaidLeave}</td>
              <td className='half'>{item.totalWorkingHour}</td>
              <td className='half'>{item.totalWorkingHour}</td>
              <td className='half'>{item.totalWorkingHour}</td>
              <td className='half'>{item.totalWorkingHour}</td>
              <td className='half'>{item.totalWorkingHour}</td>
            </tr>
            )
            })}
        </tbody>
      </table>
          )}
          <div clasName='ftr'>
            <div className='actdv'>
            <Select className='select-epp'
       placeholder='Action'
        defaultValue={action}
        onChange={setAction}
        options={actionOption}
      /><button className='button-33'>Action</button>
      </div>
          <ul className="pagination pag-multi">              
  {/* render Previous button */}
  <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
    <a className="page-link" href="/" onClick={event => handlePageClick(event, currentPage - 1)}><TbPlayerTrackPrevFilled size='15px'/></a>
  </li>
  {/* render page numbers */}
  {Array.from(Array(totalPages), (item, index) => (
    <li className={`page-item ${currentPage === index + 1 ? "active" : ""}`} key={index + 1}>
      <a className="page-link" href="/" onClick={event => handlePageClick(event, index + 1)}>{index + 1}</a>
    </li>
  ))}
  {/* render Next button */}
  <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
    <a className="page-link" href="/" onClick={event => handlePageClick(event, currentPage + 1)}><TbPlayerTrackNextFilled size='15px'/></a>
  </li>
</ul>
</div>
  </div>
    </div>
    </div>
   </div>
  </div>
  </>
  )
}

export default MultiAttendance;